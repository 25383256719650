<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbooks <span> {{ isEdit ? 'Edit' : '' }} Custom Button</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="custom-button-form" ref="custom-button-form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Custom Button</h5>
                            <label for="show_content_headline_v2" class="switch_option capsule_btn border-0">
                                <input type="checkbox" id="show_content_headline_v2" :true-value="1" :false-value="0" v-model="form.show_button" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Button Text</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" name="name" type="text" placeholder="ex: New Button" v-model="form.name" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Button Link</label>
                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.link }">
                                    <Field autocomplete="off" name="link" type="text" placeholder="https://superfitcoaching.com" v-model="form.link" rules="url" label="button link" />
                                    <span class="prefix">URL</span>
                                </div>
                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                <ErrorMessage name="link" class="text-danger" />
                            </div>
                        </div>
                        <div class="color_container mt-3">
                            <div class="group_item">
                                <label class="input_label">Button Color</label>
                                <Field autocomplete="off" name="bg_color" v-model="form.bg_color" type="text" label="element color">
                                    <color-picker v-model="form.bg_color" :classes="{ 'has-error': errors.bg_color }" />
                                </Field>
                                <ErrorMessage name="bg_color" class="text-danger" />
                            </div>
                        </div>
                        <div class="color_container mt-3">
                            <div class="group_item">
                                <label class="input_label">Button Text Color</label>
                                <Field autocomplete="off" name="text_color" v-model="form.text_color" type="text" label="element color">
                                    <color-picker v-model="form.text_color" :classes="{ 'has-error': errors.text_color }" />
                                </Field>
                                <ErrorMessage name="text_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="playbookUpdateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="playbookUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookUpdateLoader"></i> {{ playbookUpdateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))

    export default {
        name: 'Playbook Custom Button',

        data () {
            return {
                form: {
                    show_button: 0,
                    name: '',
                    link: '',
                    bg_color: '',
                    text_color: '',
                },
            };
        },

        props: {
            modelValue: Boolean,
            isEdit: {
                type: Boolean,
                default: false
            },

            button: {
                type: Object,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ColorPicker,
        },

        watch: {
            button () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            playbookUpdateLoader: state => state.playbookModule.playbookUpdateLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                savePlaybookCustomButton: 'playbookModule/savePlaybookCustomButton',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                if (vm.isEdit) {
                    vm.form = {
                        id: vm.button.id,
                        playbook_id: vm.button.playbook_id,
                        show_button: vm.button.show_button,
                        name: vm.button.name,
                        link: vm.button.link,
                        bg_color: vm.button.bg_color,
                        text_color: vm.button.text_color,
                    };
                } else {
                    vm.form = {
                        playbook_id: vm.selectedPlaybook.id,
                        show_button: 1,
                        name: '',
                        link: '',
                        bg_color: '#D4D4D4',
                        text_color: '#2C3E50',
                    };
                }
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError  = setFieldError;

                vm.savePlaybookCustomButton(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .custom-button-form {
        height: calc(100vh - 180px);
    }
</style>
